import React, { useEffect, useState, useRef } from "react"; // Import useRef
import { useNavigate, useParams } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import {
  createFormInstance,
  documentAnalyse,
  getFormById,
} from "../../../redux/slices/forms.slice";
import Loader from "../Loader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ProjectLocationAutoCompleteSuggesstionThunk } from "../../../redux/slices/tenant.slice";
import FileUpload from "./fileUpload";
import { setSnackbar } from "../../../redux/slices/common.slice";
import PersonIcon from "@mui/icons-material/Person";
import DoneIcon from "@mui/icons-material/Done";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { StepConnector, StepIconProps } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment"; // Icon for Fill Form
import ImageIcon from "@mui/icons-material/Image";
import "../../../styles/forms.css";
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${StepConnector.line}`]: {
    borderColor: theme.palette.primary.main,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

// Custom Step icons styling
const CustomStepIcon = styled("div")(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: active ? theme.palette.primary.main : "#e0e0e0",
  color: active ? "#fff" : "#757575",
}));

function StepIconComponent(props) {
  const { active, icon } = props;

  const icons = {
    1: <UploadFileIcon />,
    2: <AssignmentIcon />,
  };

  return (
    <CustomStepIcon active={active ? 1 : 0}>
      {icons[String(icon)]}
    </CustomStepIcon>
  );
}

const CustomMsg = {
  PAN: "Upload Pan card",
  Adhar: "Upload Front & Back Of Adhar Card",
  Passport: "Upload Passport",
  "Indian Driving License": "Upload Driving License",
  "US Driving License": "Upload Driving License",
};
export default function SingleForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formSections, setFormSections] = useState([]);
  const [title, setTitle] = useState("");
  const [locationOption, setLocationOption] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [errors, setErrors] = useState({}); // State to manage validation errors
  const navigate = useNavigate();

  const formRef = useRef(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageData, setImageData] = useState({});
  const [customMsgg, setCustomMsgg] = useState("");
  // Handle file input change for multiple images
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to array

    // Append new images to the existing selected images
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  // Remove a specific image from the list
  const handleRemoveImage = (imageToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove)
    );
  };

  const steps = ["Upload Document Images", "Fill Form"];

  const handleNext = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      // Append each file to the form data
      for (let i = 0; i < selectedImages.length; i++) {
        formData.append("images", selectedImages[i]); // 'images' is the key for the backend
      }

      let response = await dispatch(documentAnalyse(formData));
      const { payload } = response;
      if (payload && payload.status) {
        setImageData(payload.data);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setImageData({});
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                mt: 4,
                p: 3,
                border: "1px dashed #ccc",
                borderRadius: 2,
                width: "100%",
                margin: "auto",
              }}
            >
              {/* Image Previews */}
              {selectedImages.length > 0 ? (
                <Grid
                  container
                  spacing={1}
                  sx={{ height: "calc(100vh - 350px)", overflowY: "auto" }}
                >
                  {selectedImages.map((image, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          src={image ? URL.createObjectURL(image) : null}
                          alt={`Uploaded Image ${index}`}
                          sx={{
                            width: "auto",
                            height: "300px",
                            mb: 2,
                          }}
                          variant="rounded"
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleRemoveImage(image)}
                        >
                          Remove Image
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    height: "calc(100vh - 350px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ImageIcon
                    sx={{
                      fontSize: 300,
                      color: "#9e9e9e",
                    }}
                  />

                  <Typography variant="h6" color="textSecondary">
                    No Images Uploaded
                  </Typography>
                </Box>
              )}

              {/* Upload Button */}
              <Box
                sx={{ display: "flex", gap: "20px", alignItems: "center" }}
                mt={2}
              >
                <Typography variant="label" sx={{ fontWeight: 500 }}>
                  {customMsgg}
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  endIcon={<UploadFileIcon />}
                >
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    multiple
                    onChange={handleImageChange}
                  />
                </Button>
              </Box>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <form ref={formRef} onSubmit={handleSubmit}>
              {formSections &&
                formSections.map((section) => (
                  <Accordion key={section.id} defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      className="accordion-summary-background accordion-summary-root"
                    >
                      <Typography className="accordion-summary-typography">
                        {section.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                      <Grid container spacing={3}>
                        {section.questions.map((question) => (
                          <Grid item xs={question.width} key={question.id}>
                            <Typography variant="body1" component="label">
                              {question.label}
                              {question.required && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </Typography>
                            {errors[question.id] && (
                              <Typography
                                variant="caption"
                                color="error"
                                sx={{
                                  fontWeight: 500,
                                  marginLeft: "10px",
                                  fontSize: "16px",
                                }}
                              >
                                {errors[question.id]}
                              </Typography>
                            )}
                            {question.type === "Text Field" && (
                              <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Your answer here"
                                size="small"
                                required={question.required}
                                name={question.id}
                                value={imageData[question.label] || ""}
                                sx={{
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: errors[question.id]
                                      ? "red"
                                      : "inherit", // Set the outline color if error exists
                                  },
                                }}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors };
                                    delete newErrors[name];
                                    return newErrors;
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "CheckBox" && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={question.id}
                                    value={imageData[question.label] || ""}
                                  />
                                }
                                sx={{ fontWeight: 600 }}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "Number" && (
                              <TextField
                                type="number"
                                fullWidth
                                variant="outlined"
                                placeholder="Enter a number"
                                size="small"
                                required={question.required}
                                name={question.id}
                                value={imageData[question.label] || ""}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "Date & Time" && (
                              <TextField
                                type="datetime-local"
                                fullWidth
                                variant="outlined"
                                size="small"
                                required={question.required}
                                name={question.id}
                                value={imageData[question.label] || ""}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "Time" && (
                              <TextField
                                type="time"
                                fullWidth
                                variant="outlined"
                                size="small"
                                required={question.required}
                                name={question.id}
                                value={imageData[question.label] || ""}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "Date" && (
                              <TextField
                                type="date"
                                fullWidth
                                variant="outlined"
                                size="small"
                                required={question.required}
                                name={question.id}
                                value={imageData[question.label] || ""}
                                onChange={(e) => {
                                  const { name, value } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value;
                                    return newData;
                                  });
                                }}
                              />
                            )}
                            {question.type === "Location" && (
                              <Autocomplete
                                className="project-location-autocomplete"
                                freeSolo
                                onInputChange={(event, values) => {
                                  handleAutocompleteChange(event, values);
                                }}
                                value={imageData[question.label] || ""}
                                onChange={(e, value) => {
                                  const { name } = e.target; // Get the name and value of the input field
                                  // Update the errors state to remove the error for the current question
                                  setErrors((prevErrors) => {
                                    const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                    delete newErrors[name]; // Remove the error for the specific question using its name
                                    return newErrors; // Return the updated errors object
                                  });
                                  setImageData((prevData) => {
                                    const newData = { ...prevData };
                                    newData[question.label] = value.label;
                                    return newData;
                                  });
                                }}
                                options={locationOption}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label=""
                                    id="outlined-basic"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    name={question.id}
                                    placeholder="Please, Enter location"
                                  />
                                )}
                              />
                            )}
                            {console.log(selectedFiles[question.id])}
                            {question.type === "Media" && (
                              <FileUpload
                                idd={question.id}
                                required={question.required}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                setErrors={setErrors}
                              />
                            )}
                            {question.type === "Radio Buttons" && (
                              <FormControl
                                component="fieldset"
                                fullWidth
                                required={question.required}
                              >
                                <RadioGroup
                                  name={`${question.id}`}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  value={imageData[question.label] || ""}
                                  defaultValue={question.options[0]}
                                  onChange={(e) => {
                                    setImageData((prevData) => {
                                      const newData = { ...prevData };
                                      newData[question.label] = e.target.value;
                                      return newData;
                                    });
                                  }}
                                >
                                  {question.options.map((option, index) => (
                                    <FormControlLabel
                                      key={index}
                                      value={option}
                                      control={<Radio />}
                                      label={option}
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </form>
          </>
        );
      default:
        return "Unknown step";
    }
  };
  const fetchFormById = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId: id }));
      const { payload } = response;
      if (payload?.status) {
        setFormSections(payload?.data?.form_struct_json);
        setTitle(payload?.data?.title);

        const extractedKey = Object.keys(CustomMsg).find((key) =>
          payload?.data?.title.includes(key)
        );

        setCustomMsgg(extractedKey ? CustomMsg[extractedKey] : "Upload Images");
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFormById();
  }, []);

  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  const validateForm = (formData) => {
    const newErrors = {};
    formSections.forEach((section) => {
      section.questions.forEach((question) => {
        const value = formData[question.id];
        console.log(formData[question.id]);
        // Only validate required questions
        if (question.required) {
          if (question.type === "CheckBox") {
            // Checkbox can be unchecked, handle accor dingly
            if (!value) {
              newErrors[question.id] = `Required`;
            }
          } else if (question.type === "Media") {
            if (selectedFiles[question.id].length === 0) {
              newErrors[question.id] = `Required`;
            }
          } else if (!value) {
            newErrors[question.id] = `Required`;
          }
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formElement = formRef.current;
    let data = new FormData(formElement);
    const formData = Object.fromEntries(data);
    console.log(formData);
    if (!validateForm(formData)) return; // Prevent submission if validation fails
    setLoading(true);
    const finalData = {
      form: +id,
      submission_json: formSections.map((section) => {
        return {
          section: section.id,
          section_name: section.name,
          questions: section.questions.map((question) => {
            let isSelected = null;
            let groupValue = null;
            let images = null;
            let answer = "";

            if (question.type === "CheckBox") {
              isSelected = formData[question.id] === "on";
            } else if (
              question.type === "Select" ||
              question.type === "Radio Buttons"
            ) {
              groupValue = formData[`${question.id}`] || null;
            } else if (question.type === "Media") {
              images = selectedFiles.map((image) => ({
                base: image.base64Image,
                extension: image.extension,
              }));
            } else {
              answer = formData[question.id] || null;
            }

            return {
              question_id: question.id,
              answer: answer,
              answer_type: question.type,
              group_value: groupValue,
              is_selected: isSelected,
              required: question.required,
              images: images,
              label: question.label,
              width: question.width,
            };
          }),
        };
      }),
    };

    try {
      let response = await dispatch(
        createFormInstance({
          formData: finalData,
        })
      );
      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Form submitted Succesfully.",
          })
        );
        navigate("/forms");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: "20%", pr: 2 }}>
          <Typography
            sx={{
              color: "#00446B !important",
              fontSize: "16px !important",
              fontWeight: "bold !important",
            }}
            mb={2}
          >
            {title}
          </Typography>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomStepConnector />} // Custom vertical connector
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepIconComponent}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ display: "flex" }} mt={2}>
            <Button
              onClick={
                activeStep === steps.length - 1 ? handleReset : handleSkip
              }
              sx={{
                padding: "6px 24px !important",
                borderRadius: "5px",
                minWidth: "auto",
                backgroundColor: "#fff",
                color: "#036185 !important",
                border: 1,
                borderColor: "#273167",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#273167",
                  border: 1,
                  borderColor: "#273167",
                },
              }}
              disabled={
                activeStep !== steps.length - 1 && selectedImages.length
              }
            >
              {activeStep === steps.length - 1 ? "Back" : "Skip"}
            </Button>
            <Button
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              sx={{
                padding: "6px 24px !important",
                borderRadius: "5px",
                marginLeft: "15px",
                backgroundColor: "#036185 ",
                color: "#fff !important",
                "&:hover": {
                  backgroundColor: "#fff !important",
                  color: "#273167 !important",
                  border: 1,
                  borderColor: "#036185",
                },
              }}
              disabled={
                activeStep !== steps.length - 1 && !selectedImages.length
              }
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </Box>
        </Box>

        {/* Right: Dynamic Content */}
        <Box sx={{ width: "80%" }}>
          <Box
            sx={{
              padding: "24px",
              backgroundColor: "#ffffff",
              border: "1px solid #036185",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              height: "calc(100vh - 100px)",
              overflowY: "auto",
            }}
          >
            {getStepContent(activeStep)}
          </Box>
        </Box>
      </Box>
    </>
  );
}

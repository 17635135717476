import {
  Box,
  Button,
  Select,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  InputBase,
  Tooltip,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTable from "../../dataGrid";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import {
  getAllWorkSpaceThunk,
  statusUpdatethunk,
} from "../../../../redux/slices/tenant.slice";
import CreateWorkspace from "./CreateWorkspace";
import "../../../../styles/settings.scss";
import EyeIcon from "../../../../assets/icons/eye.svg";
import Pencil from "../../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const SwitchStyle = {
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#036185",
      "& +.MuiSwitch-track": {
        backgroundColor: "#036185",
      },
    },
  },
};

const Workspace = () => {
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [workspace, setWorkspace] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const dispatch = useDispatch();
  const fetchAllWorkspace = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllWorkSpaceThunk({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        status: statusFilter,
      })
    );
    const { payload } = response;
    if (payload.status) {
      setWorkspace(payload.data);
      setTotalRow(payload.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const handlestatus = async (params) => {
    let response = await dispatch(
      statusUpdatethunk({ id: params.row.id, status: !params.row.status })
    );
    if (response.payload.status) {
      fetchAllWorkspace();
    }
  };

  useEffect(
    () => {
      fetchAllWorkspace();
    }, // eslint-disable-next-line
    [paginationModel, statusFilter]
  );

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton sx={{ color: "#000000" }}>
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setViewOpen(params.row);
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton sx={{ color: "#000000", padding: "0px" }}>
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setIsEdit(true);
                  setViewOpen(params.row);
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function statusCell(params) {
    return (
      <Switch
        checked={params.row.status}
        onChange={() => handlestatus(params)}
        inputProps={{ "aria-label": "controlled" }}
        sx={SwitchStyle}
      />
    );
  }

  const columns = [
    {
      field: "branch_name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "address1",
      headerName: "Address",
      /* renderCell: AddressCell,**/ minWidth: 300,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          Address
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          Country
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          State
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          City
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: statusCell,
      minWidth: 100,
    },
    {
      field: "kb_action",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  const statusOptiones = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <Select
          sx={customStyles}
          name="status"
          className="basic-multi-select basic-multi-select-status"
          onChange={(event) => {
            setStatusFilter(event.target.value);
          }}
          value={statusFilter}
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
          }}
        >
          {statusOptiones.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>

        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            // component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              handleEditOpen();
            }}
          >
            Create workspace
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={
            searchTerm
              ? workspace.filter((item) =>
                  item.branch_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
              : workspace
          }
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>
      <CreateWorkspace
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        fetchAllWorkspace={fetchAllWorkspace}
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        viewOpen={viewOpen}
        setViewOpen={setViewOpen}
      />
    </>
  );
};

export default Workspace;

import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  Box,
  Collapse,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  DialogContent,
  ButtonGroup,
  FormControl,
  Typography,
  CircularProgress,
  FormLabel,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RiAddCircleLine, RiDeleteBinLine } from "react-icons/ri";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Cancel";
import TextFieldIcon from "@mui/icons-material/TextFields";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import NumericIcon from "@mui/icons-material/AttachMoney"; // Replace with a suitable numeric icon
import DateTimeIcon from "@mui/icons-material/CalendarToday"; // Replace with a suitable date-time icon
import CustomizedDialog from "../../Dialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FormRenderer from "./formRenderer";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useDispatch } from "react-redux";
import {
  createForm,
  getAllForm,
  getFormById,
  getFormTypes,
  updateForm,
} from "../../../../redux/slices/forms.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import Loader from "../../Loader";
import { FALSE } from "sass";
const initialData = {
  id: "f-2",
  title: "Safety Culture",
  description:
    "A comprehensive form to manage team safety culture and inspection data.",
  sections: [
    {
      id: "s-1",
      name: "Team Information",
      questions: [
        {
          id: "q-s-1-1",
          label: "Team Name",
          type: "Text Field",
          required: true,
          width: 12,
          options: [], // Empty options array by default
        },
        {
          id: "q-s-1-2",
          label: "Team Leader",
          type: "Text Field",
          required: true,
          width: 6,
          options: [], // Empty options array by default
        },
        {
          id: "q-s-1-3",
          label: "Team Members (comma-separated)",
          type: "Text Field",
          required: false,
          width: 6,
          options: [], // Empty options array by default
        },
      ],
    },
    {
      id: "s-2",
      name: "Inspection Details",
      questions: [
        {
          id: "q-s-2-1",
          label: "Inspection Date",
          type: "Date & Time",
          required: true,
          width: 6,
          options: [], // Empty options array by default
        },
        {
          id: "q-s-2-2",
          label: "Inspection Findings",
          type: "Text Field",
          required: true,
          width: 12,
          options: [], // Empty options array by default
        },
        {
          id: "q-s-2-3",
          label: "Action Required",
          type: "CheckBox",
          required: true,
          width: 6,
          options: [], // Empty options array by default
        },
      ],
    },
  ],
};
const customStyles = {
  width: "300px !important",
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
    height: " 20px",
    minHeight: " 10px !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};
//const initialData = [];
export default function DynamicFormBuilder({ mode }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { title, description } = location.state || {}; // Retrieve the `id` from `state`

  const [sections, setSections] = useState([
    {
      id: "s-1",
      name: "Section 1",
      questions: [
        {
          id: "q-s-1-1",
          label: "",
          options: [],
          required: false,
          type: "Text Field",
          width: 12,
        },
      ],
    },
  ]);
  const { formId } = useParams();
  const [formTitle, setFormTitle] = useState(title || "");
  const [formDescription, setFormDescription] = useState(description || "");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formTemplate, setFormTemplate] = useState(formId);
  const [formTypes, setFormTypes] = useState([]);
  const [formtype, setFormType] = useState("custom");

  const [expandedQuestionId, setExpandedQuestionId] = useState(
    sections.reduce((acc, section) => {
      const expandedQuestions = section.questions.map((q) => q.id);
      return [...acc, ...expandedQuestions]; // Keep all questions open
    }, [])
  ); // Track which question dropdown is expanded
  const navigate = useNavigate();
  const setData = (data) => {
    setSections(data?.form_struct_json);
    setFormType(data?.type);
    setFormTemplate(data?.reference_template?.id);
    setExpandedQuestionId(
      data?.form_struct_json.reduce((acc, section) => {
        const expandedQuestions = section.questions.map((q) => q.id);
        return [...acc, ...expandedQuestions]; // Keep all questions open
      }, [])
    );

    if (!title && !description && !data?.is_template) {
      setFormTitle(data?.title);
      setFormDescription(data?.description);
    }
    setIsTemplate(data?.is_template);
  };
  const fetchFormById = async (id, isTemplateChange = false) => {
    setLoading(true);
    try {
      const response = await dispatch(getFormById({ formId: id }));
      const { payload } = response;
      if (payload?.status) {
        if (isTemplateChange) {
          setSections(payload?.data?.form_struct_json);

          setExpandedQuestionId(
            payload?.data?.form_struct_json.reduce((acc, section) => {
              const expandedQuestions = section.questions.map((q) => q.id);
              return [...acc, ...expandedQuestions]; // Keep all questions open
            }, [])
          );
        } else {
          setData(payload?.data);
        }
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload?.message || "Internal server error",
          })
        );
      }
    } catch (e) {}
    setLoading(false);
  };
  const fetchAllFormTypes = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormTypes());
      const { payload } = response;
      if (payload?.status) {
        setFormTypes(payload?.data);
      }
    } catch (e) {}
    setLoading(false);
  };
  const fetchAllTemplateForms = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getAllForm({
          custom_filter: JSON.stringify({ is_template: true }),
          list: "all",
        })
      );
      const { payload } = response;
      if (payload?.status) {
        setFormTemplates(payload?.data);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    if (mode === "edit") {
      fetchFormById(formId);
    }
  }, [mode, formId]);

  useEffect(() => {
    fetchAllTemplateForms();
    fetchAllFormTypes();
  }, []);

  const questionTypes = [
    "Text Field",
    "CheckBox",
    "Number",
    "Date & Time",
    "Date",
    "Time",
    "Location",
    "Media",
    "Radio Buttons",
  ];
  const questionTypeIcons = {
    "Text Field": <TextFieldIcon />,
    CheckBox: <CheckboxIcon />,
    Number: <NumericIcon />,
    "Date & Time": <DateTimeIcon />,
    Date: <DateRangeIcon />,
    Time: <AccessTimeIcon />,
    Location: <LocationOnIcon />,
    Media: <PermMediaIcon />,
    "Radio Buttons": <RadioButtonCheckedIcon />,
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        id: `s-${sections.length + 1}`,
        name: `Section ${sections.length + 1}`,
        questions: [
          {
            id: `q-s-${sections.length + 1}-1`,
            label: "",
            options: [],
            required: false,
            type: "Text Field",
            width: 12,
          },
        ],
      },
    ]);
    setExpandedQuestionId(
      (prevState) => [...prevState, `q-s-${sections.length + 1}-1`] // Expand
    );
  };

  const addQuestion = (sectionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        setExpandedQuestionId(
          (prevState) => [
            ...prevState,
            `q-${sectionId}-${section.questions.length + 1}`,
          ] // Expand
        );
        return {
          ...section,
          questions: [
            ...section.questions,
            {
              id: `q-${sectionId}-${section.questions.length + 1}`,
              type: "Text Field",
              label: "",
              options: [],
              required: false, // Add required field to the question
              width: 12,
            },
          ],
        };
      }
      return section;
    });
    setSections(updatedSections);
  };

  const removeQuestion = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          questions: section.questions.filter((q) => q.id !== questionId),
        };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const removeSection = (sectionId) => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
  };

  const toggleQuestionDropdown = (questionId) => {
    setExpandedQuestionId(
      (prevState) =>
        prevState.includes(questionId)
          ? prevState.filter((id) => id !== questionId) // Collapse
          : [...prevState, questionId] // Expand
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "sections" &&
      destination.droppableId === "sections"
    ) {
      const reorderedSections = Array.from(sections);
      const [removed] = reorderedSections.splice(source.index, 1);
      reorderedSections.splice(destination.index, 0, removed);
      setSections(reorderedSections);
    } else if (source.droppableId === destination.droppableId) {
      const section = sections.find((sec) => sec.id === source.droppableId);
      const reorderedQuestions = Array.from(section.questions);
      const [removed] = reorderedQuestions.splice(source.index, 1);
      reorderedQuestions.splice(destination.index, 0, removed);

      const updatedSections = sections.map((sec) => {
        if (sec.id === section.id) {
          return { ...sec, questions: reorderedQuestions };
        }
        return sec;
      });

      setSections(updatedSections);
    }
  };

  const handleRequiredChange = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedQuestions = section.questions.map((q) => {
          if (q.id === questionId) {
            return { ...q, required: !q.required }; // Toggle required status
          }
          return q;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    setSections(updatedSections);
  };
  const handleWidthChange = (sectionId, questionId) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        const updatedQuestions = section.questions.map((q) => {
          if (q.id === questionId) {
            return { ...q, width: q.width === 12 ? 6 : 12 }; // Toggle required status
          }
          return q;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    });

    setSections(updatedSections);
  };

  const onSubmit = () => {
    const updatedSections = sections.map((section) => {
      const updatedQuestions = section.questions.map((question) => {
        // Check if the question has options (not all question types do)
        if (question.options && question.options.length > 0) {
          return {
            ...question,
            // Remove duplicate options
            options: removeDuplicates(question.options),
          };
        }
        return question; // If no options, return the question unchanged
      });

      return {
        ...section,
        questions: updatedQuestions,
      };
    });
    setSections(updatedSections);
    setOpenPreview(true);
  };

  const toCamelCase = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .split(" ") // Split the string by spaces
      .map((word, index) => {
        if (index === 0) {
          // Return the first word in lowercase
          return word;
        }
        // Capitalize the first letter of the other words
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(""); // Join them together to form the camelCase string
  };
  const removeDuplicates = (options) => {
    const seen = new Map();
    return options.filter((option) => {
      const lowerCaseOption = option.toLowerCase();
      if (!seen.has(lowerCaseOption)) {
        seen.set(lowerCaseOption, true); // Mark the option as seen
        return true; // Keep the first occurrence
      }
      return false; // Ignore duplicates
    });
  };

  const handleSubmit = async () => {
    if (!formTitle) {
      setTitleError("Title is Required");
      return;
    }
    if (!formDescription) {
      setDescriptionError("Description is Required");
      return;
    }

    setLoading(true);
    const updatedSections = sections.map((section) => {
      const updatedQuestions = section.questions.map((question) => {
        // Check if the question has options (not all question types do)
        if (question.options && question.options.length > 0) {
          return {
            ...question,
            // Remove duplicate options
            options: removeDuplicates(question.options),
          };
        }
        return question; // If no options, return the question unchanged
      });

      return {
        ...section,
        questions: updatedQuestions,
      };
    });

    try {
      let response;

      if (mode === "create" || isTemplate) {
        response = await dispatch(
          createForm({
            data: updatedSections,
            title: formTitle,
            description: formDescription,
            is_template: false,
            reference_template: formId,
            type: formtype,
          })
        );
      } else {
        response = await dispatch(
          updateForm({
            data: updatedSections,
            title: formTitle,
            description: formDescription,
            formId: formId,
            reference_template: formTemplate,
            type: formtype,
          })
        );
      }

      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message:
              mode === "create"
                ? "Form created Succesfully."
                : "Form updated Succesfully.",
          })
        );
        navigate("/settings/formbuilder");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const handleTemplateChange = (e) => {
    fetchFormById(e.target.value, true);

    setFormTemplate(e.target.value);
  };

  const addOption = (sectionId, questionId) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              return {
                ...q,
                options: [...q.options, ""], // Add empty option
              };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const removeOption = (sectionId, questionId, optionIndex) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              const updatedOptions = q.options.filter(
                (_, index) => index !== optionIndex
              );
              return { ...q, options: updatedOptions };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const updateQuestionOptions = (sectionId, questionId, newOptions) => {
    setSections((prevSections) =>
      prevSections.map((section) => {
        if (section.id === sectionId) {
          const updatedQuestions = section.questions.map((q) => {
            if (q.id === questionId) {
              return { ...q, options: newOptions };
            }
            return q;
          });
          return { ...section, questions: updatedQuestions };
        }
        return section;
      })
    );
  };

  const handleFormTypeChange = (e) => {
    setFormType(e.target.value);
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Box sx={{ padding: "0 12px" }}>
      <Box
        className="content-header kb-list-header mb-4"
        sx={{ alignItems: "flex-end !important" }}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate("/settings/formbuilder");
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        <Box
          variant="div"
          component="div"
          className="content-header-right"
          sx={{ alignItems: "flex-end !important" }}
        >
          <FormControl sx={{ width: "fit-content" }}>
            <Typography variant="body1" component="label">
              Type
            </Typography>
            <Select
              displayEmpty
              value={formtype ? formtype : ""} // assuming selectedFormId stores the selected id
              renderValue={(value) => {
                // Check if no value is selected
                if (!value) {
                  return (
                    <Typography color="gray" variant="label">
                      Form Type
                    </Typography>
                  );
                }

                const selectedItem = formTypes.find(
                  (item) => item.type === value
                );

                return selectedItem ? selectedItem.name : "";
              }}
              onChange={handleFormTypeChange}
              sx={customStyles}
              name="status"
              className="basic-multi-select"
              placeholder="Select Form Library"
              classNamePrefix="select"
              components={{
                IndicatorSeparator: () => null,
              }}
            >
              {formTypes?.map((item) => (
                <MenuItem key={item.type} value={item.type}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl sx={{ width: "fit-content" }}> */}
          <Box>
            <Typography variant="body1" component="label">
              Library
            </Typography>
            <Select
              displayEmpty
              value={formTemplate ? formTemplate : ""} // assuming selectedFormId stores the selected id
              renderValue={(value) => {
                // Check if no value is selected
                if (!value) {
                  return (
                    <Typography color="gray" variant="label">
                      Select Form Library
                    </Typography>
                  );
                }

                const selectedItem = formTemplates.find(
                  (item) => item.id === value
                );

                return selectedItem ? selectedItem.title : "";
              }}
              onChange={handleTemplateChange}
              sx={customStyles}
              name="status"
              className="basic-multi-select"
              placeholder="Select Form Library"
              classNamePrefix="select"
              components={{
                IndicatorSeparator: () => null,
              }}
            >
              {formTemplates.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* </FormControl> */}
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<SaveIcon />}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<VisibilityIcon />}
            onClick={onSubmit}
          >
            Preview Form
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body1" component="label">
            Form Title
          </Typography>
          <TextField
            value={formTitle}
            onChange={(e) => setFormTitle(e.target.value)}
            sx={{ backgroundColor: "white" }}
            required
            size="small"
          />
          {titleError && <div className="form-error-message">{titleError}</div>}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1" component="label">
            Form Description
          </Typography>
          <TextField
            value={formDescription}
            onChange={(e) => setFormDescription(e.target.value)}
            sx={{ backgroundColor: "white" }}
            required
            size="small"
          />
          {descriptionError && (
            <div className="form-error-message">{descriptionError}</div>
          )}
        </Grid>

        {!loading && (
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              {sections.map((section, sectionIndex) => (
                <>
                  <Box
                    style={{
                      marginBottom: "16px",
                      padding: "8px 16px",
                      borderRadius: "8px ",
                      backgroundColor: "#ffffff",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      border: "none",
                      transition: "transform 0.2s ease, box-shadow 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
                      },
                    }}
                    className={
                      formtype === "employee_induction" &&
                      sectionIndex === 0 &&
                      "form-disabled"
                    }
                  >
                    {formtype === "employee_induction" &&
                      sectionIndex === 0 && (
                        <Typography
                          variant="label"
                          sx={{ color: "red", fontWeight: "bold" }}
                        >
                          This Section Is Not Editable
                        </Typography>
                      )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        placeholder="Section Title"
                        value={section.name}
                        variant="standard"
                        onChange={(e) => {
                          const newName = e.target.value;
                          setSections((prevSections) =>
                            prevSections.map((sectionn) =>
                              sectionn.id === section.id
                                ? { ...sectionn, name: newName }
                                : sectionn
                            )
                          );
                        }}
                        sx={{ width: "250px" }}
                      />
                      {sections.length > 1 && (
                        <Button
                          onClick={() => removeSection(section.id)}
                          variant="standard"
                          color="error"
                        >
                          <CloseIcon sx={{ fontSize: "28px" }} />
                        </Button>
                      )}
                    </Box>
                    <Droppable
                      droppableId={section.id}
                      direction="vertical"
                      type="group"
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {section.questions.map((question, index) => (
                            <Draggable
                              key={question.id}
                              draggableId={question.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    marginBottom: "3px",
                                    padding: "2px",
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid
                                      sx={{
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        backgroundColor: "#fff",
                                      }}
                                      item
                                      xs={11}
                                    >
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                      >
                                        <Grid item={1}>
                                          <Box sx={{ margin: "0 5px" }}>
                                            <DragIndicatorIcon />
                                            <IconButton
                                              onClick={() =>
                                                toggleQuestionDropdown(
                                                  question.id
                                                )
                                              }
                                            >
                                              {expandedQuestionId.includes(
                                                question.id
                                              ) ? (
                                                <ArrowDropUpIcon />
                                              ) : (
                                                <ArrowDropDownIcon />
                                              )}
                                            </IconButton>
                                          </Box>
                                        </Grid>
                                        <Grid item xs={8}>
                                          <TextField
                                            placeholder="Question Title"
                                            value={question.label}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                              disableUnderline: true,
                                              sx: {
                                                "& .MuiOutlinedInput-notchedOutline":
                                                  {
                                                    border: "none",
                                                  },
                                                borderRight: "1px solid #ccc",
                                                borderRadius: "0px",
                                              },
                                            }}
                                            onChange={(e) => {
                                              const updatedSections =
                                                sections.map((sec) => {
                                                  if (sec.id === section.id) {
                                                    const updatedQuestions =
                                                      sec.questions.map((q) => {
                                                        if (
                                                          q.id === question.id
                                                        ) {
                                                          return {
                                                            ...q,
                                                            label:
                                                              e.target.value,
                                                          };
                                                        }
                                                        return q;
                                                      });
                                                    return {
                                                      ...sec,
                                                      questions:
                                                        updatedQuestions,
                                                    };
                                                  }
                                                  return sec;
                                                });
                                              setSections(updatedSections);
                                            }}
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <Select
                                            value={question.type}
                                            sx={{
                                              "& .MuiOutlinedInput-notchedOutline":
                                                {
                                                  border: "none",
                                                },
                                              "& .MuiSelect-select": {
                                                display: "flex",
                                                alignItems: "center",
                                              },
                                              "&:hover .MuiOutlinedInput-notchedOutline":
                                                {
                                                  borderColor: "#888",
                                                },
                                              "& .MuiInputBase-input": {
                                                height: "20px",
                                                minHeight: "0",
                                              },
                                            }}
                                            onChange={(e) => {
                                              const newType = e.target.value;
                                              const updatedSections =
                                                sections.map((sec) => {
                                                  if (sec.id === section.id) {
                                                    const updatedQuestions =
                                                      sec.questions.map((q) => {
                                                        if (
                                                          q.id === question.id
                                                        ) {
                                                          return {
                                                            ...q,
                                                            type: newType,
                                                            ...(newType ===
                                                              "Select" && {
                                                              options: [],
                                                            }), // Add options array if Select type
                                                          };
                                                        }
                                                        return q;
                                                      });
                                                    return {
                                                      ...sec,
                                                      questions:
                                                        updatedQuestions,
                                                    };
                                                  }
                                                  return sec;
                                                });
                                              setSections(updatedSections);
                                            }}
                                            fullWidth
                                          >
                                            {questionTypes.map((type) => (
                                              <MenuItem key={type} value={type}>
                                                <ListItemIcon>
                                                  {questionTypeIcons[type]}
                                                </ListItemIcon>
                                                <ListItemText primary={type} />
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </Grid>
                                      </Grid>

                                      <Collapse
                                        in={expandedQuestionId.includes(
                                          question.id
                                        )}
                                        timeout="auto"
                                        unmountOnExit
                                        sx={{ backgroundColor: "#f0f8fb" }}
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          p="4px"
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={question.required}
                                                onChange={() =>
                                                  handleRequiredChange(
                                                    section.id,
                                                    question.id
                                                  )
                                                }
                                              />
                                            }
                                            label="Required"
                                            sx={{
                                              ml: 2,
                                              borderRight: "1px solid #ccc",
                                              paddingRight: 2,
                                            }}
                                          />
                                          <ButtonGroup
                                            variant="outlined"
                                            aria-label="width options"
                                          >
                                            <Button
                                              onClick={() =>
                                                handleWidthChange(
                                                  section.id,
                                                  question.id
                                                )
                                              }
                                              variant={
                                                question.width === 12
                                                  ? "contained"
                                                  : "outlined"
                                              }
                                            >
                                              Full Width
                                            </Button>
                                            <Button
                                              onClick={() =>
                                                handleWidthChange(
                                                  section.id,
                                                  question.id
                                                )
                                              }
                                              variant={
                                                question.width === 6
                                                  ? "contained"
                                                  : "outlined"
                                              }
                                            >
                                              Half Width
                                            </Button>
                                          </ButtonGroup>
                                        </Box>

                                        {/* Dynamic Options Input for Select Type */}
                                        {question.type === "Radio Buttons" && (
                                          <Box p={2}>
                                            <Typography variant="subtitle2">
                                              Options:
                                            </Typography>
                                            <Grid container mb={2} spacing={2}>
                                              {question.options?.map(
                                                (option, optionIndex) => (
                                                  <Grid
                                                    item
                                                    lg={4}
                                                    key={optionIndex}
                                                  >
                                                    <Box
                                                      display="flex"
                                                      gap="2px"
                                                    >
                                                      <TextField
                                                        value={option}
                                                        onChange={(e) => {
                                                          const updatedOptions =
                                                            [
                                                              ...question.options,
                                                            ];
                                                          updatedOptions[
                                                            optionIndex
                                                          ] = e.target.value;
                                                          updateQuestionOptions(
                                                            section.id,
                                                            question.id,
                                                            updatedOptions
                                                          );
                                                        }}
                                                        size="small"
                                                        placeholder={`Option ${
                                                          optionIndex + 1
                                                        }`}
                                                        fullWidth
                                                      />
                                                      <IconButton
                                                        onClick={() =>
                                                          removeOption(
                                                            section.id,
                                                            question.id,
                                                            optionIndex
                                                          )
                                                        }
                                                        color="error"
                                                      >
                                                        <CloseIcon />
                                                      </IconButton>
                                                    </Box>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                            <Button
                                              onClick={() =>
                                                addOption(
                                                  section.id,
                                                  question.id
                                                )
                                              }
                                              variant="outlined"
                                              // startIcon={<AddIcon />}
                                            >
                                              Add Option
                                            </Button>
                                          </Box>
                                        )}
                                      </Collapse>
                                    </Grid>
                                    {section.questions.length > 1 && (
                                      <Grid xs={1} item>
                                        <IconButton
                                          onClick={() =>
                                            removeQuestion(
                                              section.id,
                                              question.id
                                            )
                                          }
                                          sx={{
                                            backgroundColor: "white",
                                            color: "#036185",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            border: "1px solid #036185",
                                            marginLeft: "20px",
                                            width: "46px !important",
                                            height: "46px !important",
                                          }}
                                        >
                                          <RiDeleteBinLine />
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ mt: 2, width: "fit-content" }}
                      onClick={() => addQuestion(section.id)}
                      className="primary-button"
                      endIcon={<RiAddCircleLine />}
                    >
                      Add Question
                    </Button>
                  </Box>
                </>
              ))}
            </DragDropContext>

            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1, width: "fit-content" }}
              onClick={addSection}
              className="primary-button"
              endIcon={<RiAddCircleLine />}
            >
              Add Section
            </Button>
          </Grid>
        )}
      </Grid>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Preview Form"}
        open={openPreview}
        handleClose={() => setOpenPreview(false)}
      >
        <DialogContent>
          <FormRenderer sections={sections} />
        </DialogContent>
      </CustomizedDialog>
    </Box>
  );
}

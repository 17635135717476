import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Checkbox,
  Avatar,
  DialogContent,
  Paper,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getFormInstanceByid,
  updateFormInstance,
} from "../../../redux/slices/forms.slice";
import FileUpload from "./fileUpload";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { ProjectLocationAutoCompleteSuggesstionThunk } from "../../../redux/slices/tenant.slice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../Loader";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
const FormRenderer = ({ mode }) => {
  const [formData, setFormData] = useState([]);
  const formRef = useRef(null);
  const { formId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [parentFormId, setParentFormId] = useState(null);
  const [errors, setErrors] = useState({});
  const [locationOption, setLocationOption] = useState([]);

  const handleInputChange = (
    value,
    questionId,
    sectionIndex,
    questionIndex
  ) => {
    const updatedFormData = [...formData];

    updatedFormData[sectionIndex].questions[questionIndex].answer = value;

    setFormData(updatedFormData);
  };
  console.log(parentFormId);
  const handleRadioChange = (e, questionId, sectionIndex, questionIndex) => {
    const { value } = e.target;

    const updatedFormData = [...formData];

    updatedFormData[sectionIndex].questions[questionIndex].group_value = value;

    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e, sectionIndex, questionIndex) => {
    const { checked } = e.target;

    // Create a deep copy of the form data to avoid directly mutating the state.
    const updatedFormData = [...formData];

    // Update the is_selected field for the checkbox question.
    updatedFormData[sectionIndex].questions[questionIndex].is_selected =
      checked;

    // Update the state.
    setFormData(updatedFormData);
  };
  const handleImageRemove = (sectionIndex, questionIndex, imgIndex) => {
    // Create a deep copy of the form data to avoid directly mutating the state.
    const updatedFormData = [...formData];

    // Get the specific question's images array.
    const images =
      updatedFormData[sectionIndex].questions[questionIndex].images;

    // Remove the image at the specified index.
    const updatedImages = images.filter((_, index) => index !== imgIndex);

    // Update the images array for that question.
    updatedFormData[sectionIndex].questions[questionIndex].images =
      updatedImages;

    // Update the state with the modified form data.
    setFormData(updatedFormData);
  };

  const validateForm = () => {
    const newErrors = {};
    formData.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.required) {
          if (question.answer_type === "CheckBox") {
            // Checkbox can be unchecked, handle accor dingly
            if (!question.is_selected) {
              newErrors[question.question_id] = `Required`;
            }
          } else if (question.answer_type === "Media") {
            if (question?.images.length === 0) {
              newErrors[question.question_id] = `Required`;
            }
          } else if (question.answer_type === "Radio Buttons") {
          } else if (!question.answer) {
            newErrors[question.question_id] = `Required`;
          }
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  console.log(errors);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    // Create a deep copy of formData to avoid direct mutation
    const updatedJson = [...formData];

    // Iterate over selectedFiles array
    selectedFiles.forEach((file) => {
      // Find the section and question in formData that matches the file's id (question id)
      updatedJson.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.question_id === file.id) {
            // Append the file to the question's images array
            question.images = [
              ...(question.images || []),
              {
                base: file.base64Image,
                extension: file.extension,
                img_url: null,
              },
            ];
          }
        });
      });
    });

    try {
      let response = await dispatch(
        updateFormInstance({
          formData: { form: parentFormId, submission_json: updatedJson },
          formId: formId,
        })
      );
      const { payload } = response;
      if (payload && payload.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "FormData updated Succesfully.",
          })
        );
        navigate("/forms");
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: payload.message || "Internal server error",
          })
        );
      }
    } catch (error) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: error || "Internal server error",
        })
      );
    }
    setLoading(false);
  };

  const fetchFormInstanceByid = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getFormInstanceByid({ formId: formId }));
      const { payload } = response;
      if (payload?.status) {
        setFormData(payload?.data?.submission_json);
        setToken(payload?.token);
        setParentFormId(payload?.data?.form);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchFormInstanceByid();
  }, []);

  let timeOut = null;
  const handleAutocompleteChange = (event, values) => {
    if (values !== "") {
      if (timeOut) clearTimeout(timeOut);

      timeOut = setTimeout(async () => {
        try {
          const response = await ProjectLocationAutoCompleteSuggesstionThunk({
            location: values,
          });
          const suggestionList = response.data; // Adjust this based on the structure of your response
          let temp = [];
          suggestionList?.predictions?.map((element) => {
            temp.push({
              label: element?.description,
              value: element?.place_id,
            });
          });
          setLocationOption(temp);
        } catch (error) {}
      }, 500);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Box
        variant="div"
        component="div"
        className="space-between media-assitant"
        mb={2}
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            navigate("/forms");
          }}
          startIcon={<ArrowBackIcon />}
          className="primary-button back-button"
        >
          Back
        </Button>
        {mode !== "view" && (
          <Box variant="div" component="div" className="content-header-right">
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>

      <form ref={formRef}>
        {formData &&
          formData.map((section, sectionIndex) => (
            <Accordion key={section.section} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expand-icon" />}
                className="accordion-summary-background accordion-summary-root"
              >
                <Typography className="accordion-summary-typography">
                  {section.section_name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className={
                  mode === "view"
                    ? "form-disabled accordion-details"
                    : "accordion-details"
                }
              >
                <Grid container spacing={3}>
                  {section.questions.map((question, questionIndex) => (
                    <Grid
                      item
                      xs={question.width || 6}
                      key={question.question_id}
                    >
                      <Typography
                        variant="label"
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px !important",
                        }}
                      >
                        {question.label}
                        {question.required && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </Typography>
                      {errors[question.question_id] && (
                        <Typography
                          variant="caption"
                          color="error"
                          sx={{
                            fontWeight: 500,
                            marginLeft: "10px",
                            fontSize: "14px",
                          }}
                        >
                          {errors[question.question_id]}
                        </Typography>
                      )}
                      {question.answer_type === "Text Field" && (
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Your answer here"
                          required={question.required}
                          name={question.question_id}
                          size="small"
                          value={question.answer || ""}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]} // Shows error if validation fails
                        />
                      )}

                      {question.answer_type === "Date" && (
                        <TextField
                          type="date"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          name={question.question_id}
                          value={question.answer || ""}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]}
                        />
                      )}
                      {question.answer_type === "Number" && (
                        <TextField
                          type="number"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          name={question.question_id}
                          value={question.answer || ""}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]}
                        />
                      )}
                      {question.answer_type === "Time" && (
                        <TextField
                          type="time"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          name={question.question_id}
                          value={question.answer || ""}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]}
                        />
                      )}
                      {question.answer_type === "Date & Time" && (
                        <TextField
                          type="datetime-local"
                          fullWidth
                          variant="outlined"
                          size="small"
                          required={question.required}
                          name={question.question_id}
                          value={question.answer || ""}
                          onChange={(e) => {
                            handleInputChange(
                              e.target.value,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]}
                        />
                      )}

                      {question.answer_type === "Location" && (
                        <Autocomplete
                          className="project-location-autocomplete"
                          freeSolo
                          size="small"
                          value={question.answer}
                          onInputChange={(event, values) => {
                            handleAutocompleteChange(event, values);
                          }}
                          onChange={(e, valuee) => {
                            handleInputChange(
                              valuee.label,
                              question.question_id,
                              sectionIndex,
                              questionIndex
                            );
                            setErrors((prevErrors) => {
                              const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                              delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                              return newErrors; // Return the updated errors object
                            });
                          }}
                          error={!!errors[question.question_id]}
                          options={locationOption}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              id="outlined-basic"
                              fullWidth
                              variant="outlined"
                              // required={question.required}
                              name={question.question_id}
                              // value={question.answer || ""}
                              placeholder="Please, Enter location"
                            />
                          )}
                        />
                      )}

                      {question.answer_type === "Radio Buttons" && (
                        <FormControl component="fieldset" fullWidth required>
                          <RadioGroup
                            name={`${question.question_id}`}
                            sx={{ display: "flex", flexDirection: "row" }}
                            value={question.group_value || ""}
                            onChange={(e) =>
                              handleRadioChange(
                                e,
                                question.question_id,
                                sectionIndex,
                                questionIndex
                              )
                            }
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Male"
                              value="Male"
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Female"
                              value="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}

                      {question.answer_type === "Media" && (
                        <>
                          <Box>
                            {question.images && question.images.length > 0 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                {/* Map through all images in question.images array */}
                                {question.images.map((image, imgIndex) => (
                                  <Box
                                    key={imgIndex}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      mb: 2,
                                    }}
                                  >
                                    <Avatar
                                      src={`${image.img_url}?${token}`}
                                      alt={`Uploaded Image ${imgIndex + 1}`}
                                      sx={{ width: 100, height: 100, mb: 2 }}
                                    />
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={() =>
                                        handleImageRemove(
                                          sectionIndex,
                                          questionIndex,
                                          imgIndex
                                        )
                                      }
                                    >
                                      Remove Image
                                    </Button>
                                  </Box>
                                ))}
                              </Box>
                            )}
                          </Box>

                          <FileUpload
                            idd={question.question_id}
                            required={question.required}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            setErrors={setErrors}
                          />
                        </>
                      )}

                      {question.answer_type === "CheckBox" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={question.is_selected || false}
                              sx={{ marginLeft: "10px" }}
                              onChange={(e) => {
                                handleCheckboxChange(
                                  e,
                                  sectionIndex,
                                  questionIndex
                                );
                                setErrors((prevErrors) => {
                                  const newErrors = { ...prevErrors }; // Create a copy of the previous errors state
                                  delete newErrors[question.question_id]; // Remove the error for the specific question using its name
                                  return newErrors; // Return the updated errors object
                                });
                              }}
                            />
                          }
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </form>
    </>
  );
};

export default FormRenderer;

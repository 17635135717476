import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Select,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTable from "../../dataGrid";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import moment from "moment";
import { getAllUsersThunk } from "../../../../redux/slices/tenant.slice";
import "../../../../styles/settings.scss";
import CreateUsers from "./CreateUsers";
import EyeIcon from "../../../../assets/icons/eye.svg";
import Pencil from "../../../../assets/icons/pencil.svg";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const Users = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [viewOpen, setViewOpen] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const dispatch = useDispatch();

  const fetchAllUsers = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllUsersThunk({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        status: statusFilter === "all" ? undefined : statusFilter,
        sort: sortKey,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setUsers(payload?.data);
      setTotalRow(payload?.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(
    () => {
      fetchAllUsers();
    }, // eslint-disable-next-line
    [paginationModel, statusFilter, sortKey]
  );

  // Function --- Formate usa number
  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumber;
  }

  const handleEditOpen = () => {
    setIsOpenDrawer(true);
    setViewOpen(false);
    setDrawerState("create");
  };
  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton sx={{ color: "#000000" }}>
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setDrawerState("view");
                  setIsOpenDrawer(true);
                  setViewOpen(params.row);
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton sx={{ color: "#000000", padding: "0px" }}>
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
                onClick={() => {
                  setDrawerState("edit");
                  setIsOpenDrawer(true);
                  setViewOpen(params.row);
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const columns = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "first_name")}
        >
          First Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },

    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "last_name")}
        >
          Last Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 350,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "email")}
        >
          Email
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "emergency_phone_number",
      headerName: "Emergency phone number",
      minWidth: 300,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "emergency_phone_number")}
        >
          Emergency phone number
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
      renderCell: (params) =>
        formatUSPhoneNumber(params?.value) == null
          ? "-"
          : `+1${formatUSPhoneNumber(params?.value)}`,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "kb_action",
      headerName: "Action",

      renderCell: ActionCell,
      // cellClassName: "sticky-right",
      // headerClassName: "sticky-right",
    },
  ];

  const statusOptiones = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <Select
          sx={customStyles}
          name="status"
          className="basic-multi-select basic-multi-select-status"
          onChange={(event) => {
            setStatusFilter(event.target.value);
          }}
          value={statusFilter}
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
          }}
        >
          {statusOptiones.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            // component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              handleEditOpen();
            }}
          >
            Create User
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={
            searchTerm
              ? users.filter((item) =>
                  item.first_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
              : users
          }
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>
      {isOpenDrawer && (
        <CreateUsers
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          isOpenDrawer={isOpenDrawer}
          fetchAllUsers={fetchAllUsers}
          viewOpen={viewOpen}
        />
      )}
    </>
  );
};

export default Users;

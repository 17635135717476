import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import DataGridTable from "./dataGrid";
import CreateKb from "./createKb";
import { useDispatch } from "react-redux";
import { getAllKnowledgeBaseThunk } from "../../redux/slices/knowledgeBase.slice";
import { setSnackbar } from "../../redux/slices/common.slice";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";
import Pencil from "../../assets/icons/pencil.svg";
import DeleteIcon from "../../assets/icons/deleteRed.svg";
import DeleteConfigureAction from "./settings/ConfigureAction/DeleteConfigureAction";
import SwapVertIcon from "@mui/icons-material/SwapVert";
const KnowledgeBase = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortKey, setSortKey] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [knowledge, setKnowledge] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const fetchAllKnowledgeBase = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllKnowledgeBaseThunk({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortKey,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setKnowledge(payload?.data);
      setTotalRow(payload?.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };
  useEffect(
    () => {
      fetchAllKnowledgeBase();
    }, // eslint-disable-next-line
    [paginationModel, sortKey]
  );
  const handleEditOpen = () => {
    setIsOpenDrawer(true);
    setViewOpen(false);
    setDrawerState("create");
  };

  function ActionCell(params) {
    return (
      <Stack direction="row" spacing={0} alignItems="center">
        <Tooltip title="View">
          <IconButton
            sx={{ color: "#000000" }}
            onClick={() => {
              setIsOpenDrawer(true);
              setDrawerState("view");
              setSelectedRowData(params.row);
            }}
          >
            <MdRemoveRedEye size={24} cursor={"pointer"} color="#036185" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Edit">
          <IconButton sx={{ color: "#000000", padding: "0px" }}>
            <img
              alt="Edit Details"
              src={Pencil}
              width={24}
              height={24}
              className="actionIcon"
              cursor={"pointer"}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("edit");
                setSelectedRowData(params.row);
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            sx={{ color: "#000000", padding: "0" }}
            onClick={() => {
              setIsDelete(true);
              setSelectedRowData(params.row);
              // getKbValues();
            }}
          >
            <img
              alt="Delete Details"
              src={DeleteIcon}
              width={24}
              height={24}
              className="actionIcon"
              cursor={"pointer"}
            />
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  function SizeCell(params) {
    return `${params.value.toFixed(2)} Mb`;
  }

  const handleSorting = (e, key) => {
    if (key) {
      if (!sortKey) {
        setSortKey(key);
      } else if (
        sortKey &&
        sortKey.startsWith("-") &&
        sortKey.substring(1) === key
      ) {
        // If the key starts with '-', remove it
        setSortKey(sortKey.substring(1));
      } else {
        // If the key doesn't start with '-', add '-'
        setSortKey(`-${key}`);
      }
    }
  };
  const columns = [
    {
      field: "kbName",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "kbName")}
        >
          Name
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "kbDesc",
      headerName: "Description",
      minWidth: 200,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "kbDesc")}
        >
          Description
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "created_at")}
        >
          Created At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(e) => handleSorting(e, "updated_at")}
        >
          Updated At
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "kb_files",
      headerName: "No. Files",
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          // onClick={(e) => handleSorting(e, "kb_files")}
        >
          No. Files
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    // { field: "kb_files", headerName: "Status", flex: 1, minWidth: 100 },
    {
      field: "kb_size",
      headerName: "File Size",
      flex: 1,
      renderCell: SizeCell,
      minWidth: 100,
      renderHeader: () => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          // onClick={(e) => handleSorting(e, "kb_size")}
        >
          File Size
          <SwapVertIcon style={{ marginLeft: 4 }} />
        </div>
      ),
    },
    {
      field: "kb_action",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <Typography variant="h2" component="h2"></Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              maxWidth: "199px",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <CiSearch color="#9F9F9F" />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              handleEditOpen();
            }}
          >
            Create Knowledge Base
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={
            searchTerm
              ? knowledge?.filter((item) =>
                  item.kbName.toLowerCase().includes(searchTerm.toLowerCase())
                )
              : knowledge
          }
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>
      {isOpenDrawer && (
        <CreateKb
          isOpenDrawer={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          viewOpen={viewOpen}
          setViewOpen={setViewOpen}
          fetchAllKnowledgeBase={fetchAllKnowledgeBase}
          editOpen={editOpen}
          setEditOpen={setEditOpen}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selectedRowData}
          isLoading={isLoading}
          fetchAllKnowledgeBase={fetchAllKnowledgeBase}
          kbmodule={"kbmodule"}
        />
      )}
    </>
  );
};

export default KnowledgeBase;

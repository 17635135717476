import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/apiUrl.constant";
import axios from "axios";

export const getAllWorkSpaceThunk = createAsyncThunk(
  "getAllWorkSpace",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_WORKSHOP}?page_number=${payload.page_number}&page_size=${payload.page_size}` +
          (payload.status !== "all" ? `&status=${payload.status}` : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllUsersThunk = createAsyncThunk(
  "getAllUsers",
  async (payload, status) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_USERS}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneWorkspaceThunk = createAsyncThunk(
  "getOneWorkspace",
  async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_WORKSHOP}?id=${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneUserThunk = createAsyncThunk("getOneUser", async (data) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_USER}${data.id}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const createWorkspaceThunk = createAsyncThunk(
  "createWorkspace",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_WORKSPACE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createUsersThunk = createAsyncThunk(
  "createUserpace",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_USER}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateWorkspaceThunk = createAsyncThunk(
  "updateWorkspace",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_WORKSPACE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  "updateUser",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_USER}${payload.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllUserRoleThunk = createAsyncThunk(
  "getAllUserRole",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_USER_ROLE}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllWorkspaceThunk = createAsyncThunk(
  "fetchAllWorkspace",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_WORKSPACE_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllKbThunk = createAsyncThunk("fetchAllAb", async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_KB}`,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const statusUpdatethunk = createAsyncThunk(
  "statusUpdate",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.WORKSPACE_STATUS_UPDATE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const statusUpdatethunkUsers = createAsyncThunk(
  "statusUpdateUser",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.USER_STATUS_UPDATE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllConfigureAction = createAsyncThunk(
  "getAllConfigureAction",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_CONFIGURE_ACTIONS}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllProject = createAsyncThunk(
  "getAllProject",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_PROJECT}?page_number=${payload.page_number}&page_size=${payload.page_size}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createProjectThunk = createAsyncThunk(
  "createProject",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_PROJECT}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const ProjectLocationAutoCompleteSuggesstionThunk =
  ("projeclocation",
  async (payload) => {
    try {
      console.log(payload);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.PROJECT_LOCATION_SUGGESSTION}?place=${payload?.location}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  });

export const updateProjectThunk = createAsyncThunk(
  "updateProject",
  async (payload) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_PROJECT}${payload?.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllConfigureTemplate = createAsyncThunk(
  "getAllConfigureTemplate",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_PROJECT}?page_number=${payload.page_number}&page_size=${payload.page_size}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneConfigureActionThunk = createAsyncThunk(
  "getOneConfigureAction",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_CONFIGURE_ACTIONS}/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createConfigureActionThunk = createAsyncThunk(
  "createConfigureAction",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_CONFIGURE_ACTIONS}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateConfigureActionThunk = createAsyncThunk(
  "updateConfigureAction",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_CONFIGURE_ACTIONS}/${payload.id}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeConfigureActionThunk = createAsyncThunk(
  "removeConfigureAction",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_CONFIGURE_ACTION}/${payload.id}/`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchActionListThunk = createAsyncThunk(
  "selectActionList",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_SELECT_ACTION_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchTemplateListThunk = createAsyncThunk(
  "selectTemplateList",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_SELECT_TEMPLATE_LIST_ACTIONS}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllTemplateListThunk = createAsyncThunk(
  "AllTemplateList",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_TEMPLATELIST}?page_number=${payload.page_number}&page_size=${payload.page_size}` +
          (payload.status !== "all" ? `&status=${payload.status}` : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createTemplateListThunk = createAsyncThunk(
  "createTemplateList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_TEMPLATELIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateTemplateListThunk = createAsyncThunk(
  "updateTemplateList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_TEMPLATELIST}/${payload.id}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneTemplateListThunk = createAsyncThunk(
  "getOneConfigureAction",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_TEMPLATELIST}/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const templateListStatusUpdatethunk = createAsyncThunk(
  "templateListStatusUpdatet",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.TEMPLATELIST_STATUS_UPDATE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// tenant slice
const tenantSlice = createSlice({
  name: "tenant",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {},
});

export const getAllTenantReport = createAsyncThunk(
  "getAllTenantReport",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_TENANT_REPORT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateReport = createAsyncThunk(
  "updateReportThunk",
  async ({ id, payload }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_REPORT}${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDashboardCountThunk = createAsyncThunk(
  "getDashboardCountThunk",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.DASHBOARD_COUNT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDashboardReportCountThunk = createAsyncThunk(
  "getDashboardReportCountThunk",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.DASHBOARD_REPORT_COUNT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDashboardSafetyBuddyCountThunk = createAsyncThunk(
  "getDashboardSafetyBuddyCountThunk",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.DASHBOARD_SAFETYBUDDY_COUNT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getTODOList = createAsyncThunk(
  "getDashboardTODOList",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.DASHBOARD_TODO_LIST}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updateTodo = createAsyncThunk(
  "updateTodo",
  async ({ data, todoId }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}${API_URL.DASHBOARD_TODO}/${todoId}`,

        data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getProjectSelectionThunk = createAsyncThunk(
  "getProjectSelectionThunk",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.PROJECT_SELECTION}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          params: payload,
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const { setData } = tenantSlice.actions; // Export the setData action
export default tenantSlice.reducer;
